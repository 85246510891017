<template>
  <v-container>
    <v-card class="mt-2 pa-3" color="secondary" dark>
      <div class="ma-3 d-flex flex-no-wrap justify-space-between">
        <div class="ma-1">
          <v-form ref="changePassword">
            <p class="font-weight-bold" style="font-size: 20px">
              Cambiar Contraseña
            </p>
            <br />
            <div>
              <br />
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'visibility_off' : 'visibility'"
                :rules="rules.required"
                :type="show1 ? 'text' : 'password'"
                label="Nueva contraseña"
                hint="Mínimo 6 caracteres"
                prepend-inner-icon="mdi-key"
                counter
                @blur="claveMatch"
                @click:append="show1 = !show1"
                required
              ></v-text-field>
              <v-text-field
                v-model="passwordConfirmation"
                :append-icon="show2 ? 'visibility_off' : 'visibility'"
                :rules="rules.required"
                label="Confirmar contraseña"
                hint="Mínimo 6 caracteres"
                prepend-inner-icon="mdi-key"
                counter
                :type="show2 ? 'text' : 'password'"
                :error-messages="claveMathError"
                @blur="claveMatch"
                @click:append="show2 = !show2"
                required
              ></v-text-field>
            </div>
            <v-btn
              block
              large
              depressed
              outlined
              color="primary"
              class="mb-3"
              @click="$router.push('/login')"
              >Cancelar</v-btn
            >
            <v-btn block large depressed color="primary" @click="change()"
              >Cambiar</v-btn
            >
          </v-form>

          <v-snackbar
            :timeout="5000"
            v-model="show"
            :multi-line="true"
            :color="color"
            >{{ mensaje }}</v-snackbar
          >
        </div>

        <v-avatar size="65%" tile v-if="!$vuetify.breakpoint.mobile">
          <v-img :src="require('@/assets/portada.jpg')"></v-img>
        </v-avatar>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/api/autenticacion";

export default {
  data: () => {
    return {
      show: true,
      show1: false,
      show2: false,
      color: "info",
      mensaje: "",
      password: "",
      token: "",
      passwordConfirmation: "",
      claveMathError: "",
      rules: {
        required: [
          (v) => !!v || "Requerido",
          (v) => (v && v.length >= 6) || "Mínimo 6 caracteres",
        ],
      },
      window: {
        height: null,
      },
    };
  },
  methods: {
    claveMatch() {
      if (this.password !== this.passwordConfirmation) {
        this.claveMathError = "La clave no coincide con la anterior";
      } else {
        this.claveMathError = "";
      }
    },
    validarToken() {
      this.token = window.location.href.split("?")[1];
      var data = new FormData();
      data.append("token", this.token);
      api
        .validarTokenRecuperacion(data)
        .then((response) => {
          if (response.data === false) {
            this.$router.push("/login");
          } else {
            this.show = true;
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    change() {
      if (this.$refs.changePassword.validate()) {
        if (this.password === this.passwordConfirmation) {
          var data = new FormData();
          data.append("Token", this.token);
          data.append("Clave", this.password);
          api
            .CambiarClave(data)
            .then(() => {
              this.show = false;
              this.mensaje = "Las credenciales han sido modificadas.";
              setTimeout(() => this.$router.push({ path: "/" }), 5000);
            })
            .catch((error) => {
              this.show = true;
              this.mensaje = error.response.data.Message;
              this.$refs.changePassword.reset();
            });
        }
      }
    },
    handleResize() {
      this.window.height = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.validarToken();
    this.handleResize();
    this.$store.dispatch("searchFormatPassword", true);
  },
  create() {
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>